import i18next from "i18next";
import React from "react";

import { ReactComponent as Leaderlogo } from "../assets/max-learn-img/leaderboard-star.svg";

import reportslogo from "../assets/max-learn-img/Reports_menu.png";
import contentlogo from "../assets/max-learn-img/Create_menu.png";
import learnerlogo from "../assets/max-learn-img/Learner_menu.png";
import helplogo from "../assets/max-learn-img/Help_menu.png";
import assignlogo from "../assets/max-learn-img/Assign_menu.png";
import organizelogo from "../assets/max-learn-img/Organize_menu.png";
import roleslogo from "../assets/max-learn-img/Roles_menu.png";
import communicationlogo from "../assets/max-learn-img/Communication_menu.png";

import * as Icon from "react-feather";
import { getCookie } from "../services/CookieManager";
import { decryptData} from "../services/LocalstorageManager"
let user = "super-admin";
let data =[]

let permissions = [];
const secretKey = "###"; 
let currentUser = getCookie("user-role");
// permissions = localStorage.getItem("role");
//const data = JSON.parse(permissions);
const encryptedRole = localStorage.getItem("role");
if (encryptedRole !== null) {
const decryptedRoleString = decryptData(encryptedRole, secretKey);
const decryptedRole = JSON.parse(decryptedRoleString);
 data = decryptedRole
// console.log("Decrypted Role:", decryptedRole);
}else {
  // console.log("No role data found in localStorage.");
}

let permissionsData = [];
permissionsData = data;
// var storedDataFeatures = localStorage.getItem("features");
// var parsedDataFeatures = JSON.parse(storedDataFeatures);

const encryptedFeatures = localStorage.getItem("features");
if (encryptedFeatures !== null) {
const decryptedRoleString = decryptData(encryptedFeatures, secretKey);
var parsedDataFeatures = JSON.parse(decryptedRoleString);
}else {
console.log("No features data found in localStorage.");
}

let squadAccess = [];
let challengeAccess = [];
let userManagementAccess = [];
let adminManagementAccess = [];
let reportAccess = [];

let squadAccessControl = [];
let challengeAccessControl = [];
let userManagementAccessControl = [];
let adminManagementAccessControl = [];
let reportAccessControl = [];

if (data === null) {
  permissionsData = [];
}
let permissionsDataManagement = [];
let permissionContentAccessControl = [];

if (permissionsData.length !== 0) {
  permissionsDataManagement = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.contentAccess === true) {
        return el._id;
      }
    }
  });
  challengeAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.challengeAccess === true) {
        return el._id;
      }
    }
  });
  squadAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.squadAccess === true) {
        return el._id;
      }
    }
  });
  userManagementAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.userManagementAccess === true) {
        return el._id;
      }
    }
  });
  adminManagementAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.adminManagementAccess === true) {
        return el._id;
      }
    }
  });
  reportAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.reportAccess === true) {
        return el._id;
      }
    }
  });
  challengeAccessControl = challengeAccess.map((el) => el._id);

  permissionContentAccessControl = permissionsDataManagement.map(
    (el) => el._id
  );
  challengeAccessControl = challengeAccess.map((el) => el._id);
  squadAccessControl = squadAccess.map((el) => el._id);

  userManagementAccessControl = userManagementAccess.map((el) => el._id);
  adminManagementAccessControl = adminManagementAccess.map((el) => el._id);
  reportAccessControl = reportAccess.map((el) => el._id);
}

const navigationConfig =[
  {
    id: "ManagingDashboard",
    title: `${i18next.t("DASHBOARD.DASHBOARD")}`,
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    //permissions:permissionContentAccess,
    children: [
      {
        id: "creatordashboard",
        title: `${i18next.t("CREATOR_DASHBOARD.CREATORS_DASHBOARD")}`,
        type: "item",
        permissions: permissionsData.length ? [currentUser] : [currentUser],
        navLink: "/creator-dashboard",
      },
      {
        id: "dashboard",
        title: `${i18next.t("DASHBOARD.MANAGER_DASHBOARD")}`,
        type: "item",
        permissions: permissionsData.length ? [currentUser] : [currentUser],
        navLink: "/dashboard",
      },
    ],
  },
  {
    id: "Leaderboards",
    title: `${i18next.t("LEADERBOARDS.LEADERBOARD")}`,
    type: "collapse",
    icon: <Leaderlogo  className="leader_lkj"  />,

    permissions: permissionsData.length ? [currentUser] : [currentUser],
    //permissions:permissionContentAccess,
    children: [
      {
        id: "individual",
        title: `${i18next.t("LEADERBOARDS.INDIVIDUALS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        //permissions: permissionContentAccess,
        navLink: "/individuals",
        // activeLink: "/data-list/category",
      },
      {
        id: "squad",
        title: `${i18next.t("LEADERBOARDS.SQUADS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        navLink: "/leaderboardSquads",
        //activeLink: "/leaderboardSquads?pageNum=1",
      },
    ],
  },
  {
    id: "reports",
    title: `${i18next.t("REPORTS.REPORT")}`,
    type: "collapse",
    icon: <img src={reportslogo} className="leader_lkj" />,
    content: "permissions",
    permissions: reportAccessControl,
    children: [
      {
        id: "Overview",
        title: `${i18next.t("REPORTS.OVERVIEW")}`,
        type: "item",
        navLink: "/overview",
        permissions: reportAccessControl,
      },
      {
        id: "uasageanalytics",
        title: `${i18next.t("USAGE_ANALYTICS.USAGE_ANALYTIC")}`,
        type: "item",
        navLink: "/usage_analytics",
        permissions: reportAccessControl,
      },
      {
        id: "learners",
        title: `${i18next.t("REPORTS.TOTAL_LEARNERS_LEARN")}`,
        type: "item",
        navLink: "/report/learners",
        permissions: reportAccessControl,
      },
      // {
      //   id: "reporttopic",
      //   title: `${i18next.t("REPORTS.TOPICS")}`,
      //   type: "item",
      //   navLink: "/report/topics",
      //   permissions: reportAccessControl,
      // },
      {
        id: "ChallengesReports",
        title: `${i18next.t("REPORTS.CHALLENGES")}`,
        type: "item",
        navLink: "/report/challenges",
        permissions:
          parsedDataFeatures && parsedDataFeatures.challenges
            ? parsedDataFeatures.challenges === true
              ? reportAccessControl
              : ""
            : "",
      },
      {
        id: "CertsReports",
        title: `${i18next.t("REPORTS.CERTS")}`,
        type: "item",
        navLink: "/report/certs",
        permissions:
          parsedDataFeatures && parsedDataFeatures.certs
            ? parsedDataFeatures.certs === true
              ? reportAccessControl
              : ""
            : "",
      },
      {
        id: "LearQuestsReports",
        title: `${i18next.t("REPORTS.LEARN_QUESTS")}`,
        type: "item",
        navLink: "/report/quests",
        permissions:
          parsedDataFeatures && parsedDataFeatures.learnQuest
            ? parsedDataFeatures.learnQuest === true
              ? reportAccessControl
              : ""
            : "",
      },
      {
        id: "ReportsGoals",
        title: `${i18next.t("CREATOR_DASHBOARD.GOALS")}`,
        type: "item",
        navLink: "/report/goals_report",
        permissions:
          parsedDataFeatures && parsedDataFeatures.goals
            ? parsedDataFeatures.goals === true
              ? reportAccessControl
              : ""
            : "",
      },
      {
        id: "Feedbacks",
        title: `${i18next.t("MANAGE_FEEDBAcKS_SIDE.MANAGE_FEEDBAcKS")}`,
        type: "item",
        navLink: "/feedbacks",
        permissions: reportAccessControl,
      },
    ],
  },

  {
    type: "groupHeader",
    groupTitle: "CONTENTS",
    permissions: permissionContentAccessControl,
  },
  {
    id: "managecontent",
    title: `${i18next.t("CONTENTS.MANAGE_CONTENT")}`,
    type: "collapse",
    icon: <img src={contentlogo} className="leader_lkj" />,
    content: "permissions",
    // permissions: permissionContentAccess,
    permissions: permissionContentAccessControl,
    children: [
      {
        id: "ai-generation",
        title: `${i18next.t("AI_GENERATION_SIDE.AI_GENERATION")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/ai-generation",
      },

      {
        id: "KLP",
        title: `${i18next.t("CONTENTS.KEY_LEARNING_POINTS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/klp",
      },
      {
        id: "question",
        title: `${i18next.t("CREATOR_DASHBOARD.QUESTIONS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/questions",
      },
      {
        id: "decks",
        title: `${i18next.t("CREATOR_DASHBOARD.DECKS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/decks",
        // activeLink: "/data-list/decks?PageNum=1",
      },
      {
        id: "external_media",
        title: `${i18next.t("CREATOR_DASHBOARD.External")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/external_media",
      },
    ],
  },
  {
    id: "organizecontent",
    title: `${i18next.t("CONTENTS.ORGANIZE_CONTENT")}`,
    type: "collapse",
    icon: <img src={organizelogo} className="leader_lkj" />,
    content: "permissions",
    permissions: permissionContentAccessControl,
    children: [
      {
        id: "category",
        title: `${i18next.t("CREATOR_DASHBOARD.CATEGORIES")}`,
        type: "item",
        //icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/category",
        className: "ml-5",
        // activeLink: "/data-list/category",
      },
      {
        id: "subjects",
        title: `${i18next.t("CREATOR_DASHBOARD.SUBJECTS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/subject",
      },
      {
        id: "topic",
        title: `${i18next.t("CREATOR_DASHBOARD.TOPICS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/topics",
      },
      {
        id: "cstUpload",
        title: `${i18next.t("CST_UPLOAD_SIDE.CST_UPLOAD")}`,
        type: "item",
        // icon: <Icon.Grid size={20} />,
        permissions: permissionContentAccessControl,
        navLink: "/cstupload",
      },
    ],
  },
  {
    id: "Assigntraining",
    title: `${i18next.t("CONTENTS.ASSIGN_CONTENT")}`,
    type: "collapse",
    icon: <img src={assignlogo} className="leader_lkj" />,
    content: "permissions",
    permissions: challengeAccessControl,
    children: [
      {
        id: "challengs",
        title: `${i18next.t("CREATOR_DASHBOARD.CHALLENGES")}`,
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/data-list/challenges",
        permissions:
          parsedDataFeatures && parsedDataFeatures.challenges
            ? parsedDataFeatures.challenges === true
              ? challengeAccessControl
              : false
            : "",
      },
      {
        id: "learnquests",
        title: `${i18next.t("CREATOR_DASHBOARD.LEARN_QUEST")}`,
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/data-list/learn-quests",
        permissions:
          parsedDataFeatures && parsedDataFeatures.learnQuest
            ? parsedDataFeatures.learnQuest === true
              ? [currentUser]
              : false
            : "",
      },
      {
        id: "Certs",
        title: `${i18next.t("CREATOR_DASHBOARD.CERTS")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        navLink: "/data-list/certs",
        permissions:
          parsedDataFeatures && parsedDataFeatures.certs
            ? parsedDataFeatures.certs === true
              ? [currentUser]
              : false
            : "",
      },

      {
        id: "Goals",
        title: `${i18next.t("CREATOR_DASHBOARD.GOALS")}`,
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/goals",
        permissions:
          parsedDataFeatures && parsedDataFeatures.goals
            ? parsedDataFeatures.goals === true
              ? [currentUser]
              : false
            : "",
      },
    ],
  },
  {
    id: "managelearnings",
    title: `${i18next.t("MANAGE_LEARNINGS.MANAGE_LEARNINGS_TAB")}`,
    type: "collapse",
    icon: <img src={learnerlogo} className="leader_lkj" />,
    content: "permissions",
    permissions: userManagementAccessControl,
    children: [
      {
        id: "Learners",
        title: `${i18next.t("CREATOR_DASHBOARD.MANAGE_LEARNERS")}`,
        //icon: <Icon.Grid size={20} />,
        type: "item",
        content: "permissions",
        permissions: userManagementAccessControl,
        navLink: "/data-list/learners",
      },
      {
        id: "learnerRole",
        title: `${i18next.t("LEARNER_UPLOAD_SIDE.LEARNER_UPLOAD")}`,
        type: "item",
        // icon: <Icon.Grid size={20} />,
        permissions: permissionsData.length ? [currentUser] : [currentUser],
        navLink: "/learnerroleupload",
      },
      {
        id: "departmentRole",
        title: `${i18next.t("LEARNER_UPLOAD_SIDE.DEPARTMENT_UPLOAD")}`,
        type: "item",
        //icon: <Icon.Grid size={20} />,
        permissions: permissionsData.length ? [currentUser] : [currentUser],
        navLink: "/departmentroleupload",
      },
      {
        id: "DepartmentMaster",
        title: `${i18next.t("DEPARTMENTS_SIDE.DEPARTMENTS")}`,
        // icon: <Icon.Grid size={20} />,
        type: "item",
        permissions: permissionsData.length ? [currentUser] : [currentUser],
        navLink: "/departments",
      },
      {
        id: "JobTitle",
        title: `${i18next.t("JOB_TITLE_SIDE.JOB_TITLE")}`,
        //icon: <Icon.Grid size={20} />,
        type: "item",
        permissions: permissionsData.length ? [currentUser] : [currentUser],
        navLink: "/jobtitle",
      },
      {
        id: "Squads",
        title: `${i18next.t("MANAGE_SQUADS_SIDE.MANAGE_SQUADS")}`,
        //icon: <Icon.Grid size={20} />,
        type: "item",
        content: "permissions",
        permissions: squadAccessControl,
        navLink: "/data-list/squadslist",
      },
    ],
  },

  {
    type: "groupHeader",
    groupTitle: "OTHER",
    permissions: adminManagementAccessControl,
  },
  {
    id: "manageusers",
    title: `${i18next.t("MANAGE_USER_ROLE_SIDE.MANAGE_USER_ROLE")}`,
    type: "collapse",
    icon: <img src={roleslogo} className="leader_lkj" />,
    content: "permissions",
    permissions: adminManagementAccessControl,
    children: [
      {
        id: "users",
        title: `${i18next.t("MANAGE_USER_ROLE_SIDE.USER_ROLE")}`,
        type: "item",
        // icon: <Icon.Circle size={12} />,
        navLink: "/data-list/userslist",
        permissions: adminManagementAccessControl,
      },
      {
        id: "permission",
        title: `${i18next.t("MANAGE_USER_ROLE_SIDE.PERMISSION_SETTING")}`,
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/data-list/permission-settings",
        permissions: adminManagementAccessControl,
      },
    ],
  },

  {
    id: "Learnercommunication",
    title: `${i18next.t("MANAGE_USER_ROLE_SIDE.LEARNER_COMMUNICATION")}`,
    type: "collapse",
    icon: <img src={communicationlogo} className="leader_lkj" />,
    content: "permissions",
    permissions: currentUser,
    children: [
      {
        id: "didYouKnow",
        title: `${i18next.t("MANAGE_USER_ROLE_SIDE.DID_YOU_KNOW")}`,
        icon: <Icon.Grid size={20} />,
        type: "item",
        permissions: [currentUser],
        navLink: "/data-list/did-you-know",
      },
      {
        id: "customNotification",
        title: `${i18next.t("MANAGE_USER_ROLE_SIDE.CUSTOM_NOTIFICATION")}`,
        icon: <Icon.Grid size={20} />,
        type: "item",
        permissions: [currentUser],
        navLink: "/data-list/custom-notification",
      },
    ],
  },
  {
    id: "helpAndSupport",
    title: `${i18next.t("MANAGE_USER_ROLE_SIDE.HELP_SUPPORT")}`,
    icon: <img src={helplogo} className="leader_lkj" />,
    type: "collapse",
    permissions: [currentUser],
    children: [
      {
        id: "help",
        title: `${i18next.t("MANAGE_USER_ROLE_SIDE.HELP_SUPPORT")}`,
        type: "item",
        permissions: [currentUser],
        navLink: "/data-list/help-and-support",
      },
      {
        id: "learnerSupport",
        title: `${i18next.t("MANAGE_USER_ROLE_SIDE.LEARNER_SUPPORT")}`,
        type: "item",
        permissions: [currentUser],
        navLink: "/data-list/support",
      },
    ],
  },
];

export default navigationConfig;
