const initialState = {
  quests: [],
};

const questReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_QUESTS":
      const data = {
        ...state,
        quests: [...action.payload.quests],
        count: action.payload.count,
      };
      return data;
    default:
      return state;
  }
};

export default questReducer;
