import { combineReducers } from "redux";
import { ManageSquadListReducer } from "./squadsListing";
import { ManageMembersListReducer } from "./membersListing";
import {ManageSquadChallengesListReducer} from "./squadschallenges";
import {ManageSquadMembersListReducer} from "./squadMembersList";
import { ManageMemberListReducer } from "./SquadMember";

const squadReducers = combineReducers({
  manageSquadData: ManageSquadListReducer,
  manageMemberData: ManageMembersListReducer,
  manageSquadChallengesData:ManageSquadChallengesListReducer,
  manageSquadMembersData:ManageSquadMembersListReducer,
  manageSquadMemberData: ManageMemberListReducer
});

export default squadReducers;
