import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import LoadingStateReducer from "./loading-state";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import ManageDeckReducer from "./decks";
import ManageCategoryListReducer from "./categories";
import topicsReducer from "./topics";
import ManageSubjectReducer from "./subjects";
import klpReducer from "./klp";
import ManageQuestionsListReducer from "./questions";
import ManageSquadListReducer from "./squads";
import ManageFlasCardReducer from "./flashcard";
import Squads from "./squads/";
import ManageChallengeReducer from "./challenges";
import learnersReducer from "./learners";
import UsersReducer from "./users/";
import ManagePermissionsListReducer from "./permissions";
import ManageCertsListReducer from "./certs";
import questReducer from "./quests";
import ManageLeaderboardReducer from "./leaderboard";
import ManageGoalsListReducer from "./goals";
import ManageTheme from './theme';

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  loadingState: LoadingStateReducer,
  dataList: dataList,
  manageDekList: ManageDeckReducer,
  manageCategoryData: ManageCategoryListReducer,
  topicsData: topicsReducer,
  subjectData: ManageSubjectReducer,
  klpData: klpReducer,
  manageQuestionsData: ManageQuestionsListReducer,
  flashData: ManageFlasCardReducer,
  squads: Squads,
  challenegeData: ManageChallengeReducer,
  learnersData: learnersReducer,
  usersData: UsersReducer,
  permissionData: ManagePermissionsListReducer,
  certsData: ManageCertsListReducer,
  questData: questReducer,
  manageIndividualData: ManageLeaderboardReducer,
  manageGoalsData: ManageGoalsListReducer,
  manageTheme:ManageTheme
});

export default rootReducer;
