const initialState = {
    list: [],
    total: "",
    searchKey: "",
  };
  
  export const ManageSquadMembersListReducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "MANAGE_SQUADS_MEMBERS_RESPONSE":
        return {
          ...state,
          list: [...action.data.list],
          total: action.data.total,
          rulesetDetails:action.data.rulesetDetails
        };
  
      default:
        return state;
    }
  };
  
