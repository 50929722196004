const CryptoJS = require('crypto-js');

// Secret key for encryption (make sure to keep this secret)
const secretKey = "###";

// Function to encrypt data
function encryptData(data, key) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

// Function to decrypt data
function decryptData(ciphertext, key) {
    const bytes  = CryptoJS.AES.decrypt(ciphertext, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

module.exports = {
    encryptData,
    decryptData
};