const initialState = {
    list:[],
    total:"",
    searchKey:"",

}


const ManageDeckReducer = (state = initialState, action) =>{
    switch(action.type){
        case "MANAGE_ALL_DECK_RESPONSE":
            
                return {
                    ...state,   
                    list: [...action.data.list],
                    total: action.data.total,
                }

                default: 
                return state
            
    }

 
}

export default ManageDeckReducer