const initialState = {
  klp: [],
};

const klpReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_KLP":
      const data = {
        ...state,
        klp: [...action.payload.klp],
        count: action.payload.count,
      };
      return data;
    default:
      return state;
  }
};

export default klpReducer;
