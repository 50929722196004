import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import maxlogodash from "../../../../assets/max-learn-img/MaxLearn_Logo.png";
import { getCookie } from "../../../../services/CookieManager";
import { connect } from 'react-redux';

class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
      theme
    } = this.props;
    return (
      <div className="navbar-header" style={{backgroundColor:theme,paddingTop:0,display:"flex",justifyContent:"center"}}> 
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/creator-dashboard" className="navbar-brand">
              <img
                src={
                  getCookie("Companyimage") &&
                  getCookie("Companyimage") !== "undefined"
                    ? getCookie("Companyimage")
                    : maxlogodash
                }
                className="dash_logoallnew"
              />
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.manageTheme?.theme?.logoBackground,
});

export default connect(mapStateToProps)(SidebarHeader);
