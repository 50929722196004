const initialState = {
  list: [],
  total: "",
  searchKey: "",
};

 export  const ManageCertsListReducer = (state = initialState, action) => {
    switch (action.type) {
    case "MANAGE_CERTS_RESPONSE":
      return {
        ...state,
        list: [...action.data.list],
        total: action.data.total,
      };

    default:
      return state;
  }
};

export default ManageCertsListReducer;
