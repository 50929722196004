const initialState = {
  list: [],
  total: "",
  searchKey: "",
};

const ManageChallengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MANAGE_ALL_CHALLENGE_RESPONSE":
      return {
        ...state,
        list: [...action.data.list],
        total: action.data.total,
      };

    default:
      return state;
  }
};

export default ManageChallengeReducer;
