import {SETTHEME} from '../../actions/theme/types';

// const defaultTheme = {
//   logoBackground:"#FFFFFF", 
//   menuBackground:"#FFFFFF",

//   defaultMenu:"#FFFFFF",
//   selectedMenu:"#CC0433",


//   defaultMenuItem:"#F6F7FA",
//   selectedMenuItem:"#CC0433",
  
  
//   defaultMenuFont:"black",
//   selectedMenuItemFont:"black",

//   //Icons
//   inActiveMenuIcons:"#CC0433",
//   activeMenuIcons:"#F6F7FA",
  
//   bottomFooterBackground:"#FFFFFF",
  
//   hoverDashboardCardItemBackground:"#CC0433",

//   //contained button
//   containedButtonBackground:"#CC0433",
//   containedButtonBackgroundActive:"#CC0433",
//   containedButtonBackgroundHover:"#525252",

//   //outline button
//   outlinedButtonBackground:"#CC0433",

//   //checkbox
//   selectedCheckbox:"#CC0433",

//   //radio
//   selectedRadio:"#CC0433",

//   //loader
//   loader:"#CC0433",

//   //toast
//   toastMessage:"#5733FF",  

//   loginBackgroundImage:"url('https://www.aaos.org/globalassets/aaos-global/aaos-logo-image-1.svg')",
//   loginLogoImage:"https://www.aaos.org/globalassets/aaos-global/aaos-logo-image-1.svg",
//   icoIcon:"https://www.aaos.org/contentassets/72faca73833746f98645bc3cea94db5f/favicon_01.ico"
// }

const defaultTheme = null;

const initialState = {
  theme: null
};

try {
  const storedTheme = localStorage.getItem('theme');
  if (storedTheme) {
    initialState.theme = JSON.parse(storedTheme);
  }
} catch (error) {
  console.error("Error reading theme from localStorage:", error);
  initialState.theme = defaultTheme;
}

const themeReducer = (state = initialState, action) => {
  if (!action) {
    console.error("Received undefined action");
    return state;
  }

  switch (action.type) {
    case 'SETTHEME':
      if (action?.data) {
        try {
          localStorage.setItem('theme', JSON.stringify(action.data));
        } catch (error) {
          console.error("Error saving theme to localStorage:", error);
        }
      }
      return { ...state, theme: action.data };
    default:
      return state;
  }
};

export default themeReducer;
